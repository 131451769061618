import React, { useState, useEffect, useRef } from 'react';
import { fabric } from 'fabric';

import { useAuthContext } from './auth';
import { noshiData } from './noshiData';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';

type NoshiYoutData = { id: string, label: string, data: any[], }[];
type NoshiOmotegakiData = { id: string, label: string, mizuhiki: string, data: any[], }[];
type NoshiSizeData = { id: string, width: number, }[];
type ModifyNaireTextData = {
  fontSize?: number,
  top?: number,
  left?: number,
  scaleX?: number,
  scaleY?: number,
};
type AlertProps = {
  open: boolean,
  type?: "success" | "info" | "warning" | "error",
  title?: string,
  message?: string,
}

const initNoshiData = noshiData;

function Noshi() {

  const authContext = useAuthContext();

  const canvasParentEl = useRef<HTMLDivElement>(null);
  const canvasEl = useRef(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [noshiYoutData, setNoshiYoutData] = useState<NoshiYoutData>([]);
  const [canvasData, setCanvasData] = useState<fabric.Canvas | null>(null);
  const [dpi, setDpi] = useState(0);
  const [noshiYoutId, setNoshiYoutId] = useState('');
  const [noshiOmotegakiData, setNoshiOmotegakiData] = useState<NoshiOmotegakiData>([]);
  const [noshiOmotegakiId, setNoshiOmotegakiId] = useState('');
  const [noshiOmotegakiLabel, setNoshiOmotegakiLabel] = useState('');
  const [noshiOmotegakiImage, setNoshiOmotegakiImage] = useState('');
  const [noshiSizeData, setNoshiSizeData] = useState<NoshiSizeData>([]);
  const [noshiSizeId, setNoshiSizeId] = useState('');
  const [noshiSizeWidth, setNoshiSizeWidth] = useState(0);
  const [naireFlag, setNaireFlag] = useState(false);
  const [naireText, setNaireText] = useState('');
  const [modifyNaireTextData, setModifyNaireTextData] = useState<ModifyNaireTextData>({});
  const [alertProps, setAlertProps] = useState<AlertProps>({ open: false, });

  const canvasAllClear = () => {
    canvasData?.getObjects().forEach((object) => canvasData.remove(object));
    canvasData?.discardActiveObject();
    canvasData?.renderAll();
  };
  const addImage = (url: string, width: number,) => {
    fabric.loadSVGFromURL(url, (objects, options) => {
      const imageGroup = fabric.util.groupSVGElements(objects, options);
      imageGroup.selectable = false;
      imageGroup.hoverCursor = 'default';
      imageGroup.objectCaching = false;
      imageGroup.scaleToWidth(width);
      canvasData?.add(imageGroup);
      canvasData?.centerObjectV(imageGroup);
      canvasData?.sendToBack(imageGroup);
    });
  };
  const addText = (text: string, obj: {}, vertical: boolean = true, center: boolean = true) => {
    const originText = text;
    const replaceText = vertical ? originText.replace(/(.)(?=.)/g, "$1\n") : originText;
    const fontFamily = vertical ? "nsjpbv, 'Noto Serif JP'" : "'Noto Serif JP'";
    const textObject = new fabric.Text(replaceText, {
      ...obj,
      lineHeight: 1.0,
      fontFamily: fontFamily,
      fontWeight: 700,
      padding: 10,
      objectCaching: false,
    });
    canvasData?.add(textObject);
    if (center) {
      canvasData?.centerObjectH(textObject);
    }
    setTimeout(() => {
      canvasData?.requestRenderAll();
    }, 250);
  };
  const calculateFontSize = (percentage: number) => {
    const canvasWidth = canvasData?.getWidth();
    const ratio = percentage / 100;
    return canvasWidth ? canvasWidth * ratio : 16;
  };
  const calculateTopPos = (percentage: number) => {
    const canvasHeight = canvasData?.getHeight();
    const ratio = percentage / 100;
    return canvasHeight ? canvasHeight * ratio : 0;
  };
  const createRandomString = () => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (var i = 0; i < 20; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }
  const addSignature = () => {
    const signature = 'のしかけジェネレーター\nhttps://noshikake-generator.app'
    const fontSizePercentage = 0.5;
    const calculatedFontSize = calculateFontSize(fontSizePercentage);
    const topPosPercentage = 97.5;
    const calculatedTopPos = calculateTopPos(topPosPercentage);
    addText(signature, {
      fontSize: calculatedFontSize,
      top: calculatedTopPos,
      left: 4,
      selectable: false,
      hoverCursor: 'default',
    }, false, false);
  };
  const handleOnChangeNoshiYouto = (event: SelectChangeEvent) => {
    canvasAllClear();
    setNoshiYoutId(event.target.value as string);
    if (event.target.value !== '') {
      const newData = noshiYoutData.filter(item => item.id === event.target.value);
      setNoshiOmotegakiData([...newData[0].data]);
    } else {
      setNoshiOmotegakiData([]);
    }
    setNoshiOmotegakiId('');
    setNoshiOmotegakiLabel('');
    setNoshiOmotegakiImage('');
    setNoshiSizeData([]);
    setNoshiSizeId('');
    setNoshiSizeWidth(0);
    setNaireFlag(false);
    setNaireText("");
    setModifyNaireTextData({});
  };
  const handleOnChangeNoshiOmotegaki = (event: SelectChangeEvent) => {
    canvasAllClear();
    setNoshiOmotegakiId(event.target.value as string);
    if (event.target.value !== '') {
      const newData = noshiOmotegakiData.filter(item => item.id === event.target.value);
      setNoshiOmotegakiLabel(newData[0].label);
      setNoshiOmotegakiImage(newData[0].mizuhiki);
      setNoshiSizeData([...newData[0].data]);
    } else {
      setNoshiOmotegakiLabel('');
      setNoshiOmotegakiImage('');
      setNoshiSizeData([]);
    }
    setNoshiSizeId('');
    setNoshiSizeWidth(0);
    setNaireFlag(false);
    setNaireText("");
    setModifyNaireTextData({});
  };
  const handleOnChangeNoshiSize = (event: SelectChangeEvent) => {
    canvasAllClear();
    setNoshiSizeId(event.target.value as string);
    if (event.target.value !== '') {
      const canvasWidth = canvasData?.getWidth() || 0;
      const fontSizePercentage = 5;
      const calculatedFontSize = calculateFontSize(fontSizePercentage);
      const topPosPercentage = 5;
      const calculatedTopPos = calculateTopPos(topPosPercentage);
      const newData = noshiSizeData.filter(item => item.id === event.target.value);
      if (!authContext?.isSignedIn) {
        addSignature();
      }
      addImage(`${noshiOmotegakiImage}`, canvasWidth);
      addText(noshiOmotegakiLabel, {
        fontSize: calculatedFontSize,
        top: calculatedTopPos,
      });
      setNoshiSizeWidth(newData[0].width);
      setNaireFlag(true);
    } else {
      setNoshiSizeWidth(0);
      setNaireFlag(false);
    }
    setNaireText("");
    setModifyNaireTextData({});
  };
  const handleOnClickAddNaireText = () => {
    const fontSizePercentage = 4;
    const calculatedFontSize = calculateFontSize(fontSizePercentage);
    const topPosPercentage = 60;
    const calculatedTopPos = calculateTopPos(topPosPercentage);
    addText(naireText, {
      fontSize: calculatedFontSize,
      top: calculatedTopPos,
    });
    setNaireText("");
  };
  const handleOnClickModifyNaireText = () => {
    const activeObj = canvasData?.getActiveObject();
    if (activeObj) {
      canvasData?.remove(activeObj);
    }
    addText(naireText, {
      fontSize: modifyNaireTextData.fontSize,
      top: modifyNaireTextData.top,
      left: modifyNaireTextData.left,
      scaleX: modifyNaireTextData.scaleX,
      scaleY: modifyNaireTextData.scaleY,
    });
    setNaireText("");
  };
  const handleOnClickSelectedDelete = () => {
    const activeObjs = canvasData?.getActiveObjects();
    if (activeObjs && activeObjs?.length > 0) {
      activeObjs.forEach((activeObj) => {
        canvasData?.remove(activeObj);
      });
      canvasData?.discardActiveObject();
      canvasData?.requestRenderAll();
    } else {
      setAlertProps({
        open: true,
        type: 'warning',
        title: '文字を選択して下さい。',
        message: '文字の削除は、先に対象の文字を選択する必要があります。',
      });
    }
  };
  const handleOnClickSelectedCenter = () => {
    const activeObj = canvasData?.getActiveObject();
    if (activeObj) {
      canvasData?.centerObjectH(activeObj);
    } else {
      setAlertProps({
        open: true,
        type: 'warning',
        title: '文字を選択して下さい。',
        message: '文字の中央揃えは、先に対象の文字を選択する必要があります。',
      });
    }
  };
  const handleOnClickAlignment = (position: string) => {
    const activeObj = canvasData?.getActiveObject();
    const activeObjs = canvasData?.getActiveObjects();
    if (typeof activeObj !== "undefined" && (activeObjs && activeObjs?.length > 1)) {
      let pos = 0;
      if (position === 'top') {
        pos = activeObj.getCoords()[0].y;
      } else {
        pos = activeObj.getCoords()[3].y;
      }
      canvasData?.discardActiveObject();
      activeObjs.forEach((textObject) => {
        let itemPos = 0;
        if (position === 'bottom' && typeof textObject.height !== 'undefined') {
          itemPos = pos - textObject.height - 1;
        } else {
          itemPos = pos;
        }
        canvasData?.setActiveObject(textObject);
        textObject.set({
          top: itemPos,
        });
        textObject.setCoords();
      });
      if (canvasData) {
        const newActiveObjs = new fabric.ActiveSelection(activeObjs, {
          canvas: canvasData,
        });
        canvasData?.setActiveObject(newActiveObjs);
      }
      canvasData?.requestRenderAll();
    } else {
      setAlertProps({
        open: true,
        type: 'warning',
        title: '文字を選択して下さい。',
        message: '文字を揃えるには、先に対象の文字を選択する必要があります。',
      });
    }
  };
  const handleOnClickImageDownload = () => {
    const canvasWidth = canvasData?.getWidth();
    if (typeof canvasWidth !== "undefined") {
      const convertedWidth = noshiSizeWidth * dpi;
      const multiplierNum = convertedWidth / canvasWidth;
      const dataURL = canvasData?.toDataURL({
        format: 'png',
        multiplier: multiplierNum,
      });
      const downloadIframe = iframeRef.current;
      if (downloadIframe?.contentDocument) {
        const anchor = downloadIframe.contentDocument.createElement("a");
        anchor.href = dataURL ? dataURL : "";
        anchor.download = createRandomString() + '.png';
        anchor.click();
        anchor.remove();
      };
    } else {
      setAlertProps({
        open: true,
        type: 'error',
        title: 'ダウンロードできませんでした。',
        message: '画像のダウンロードに失敗しました。お使いのブラウザでダウンロードできない可能性もあります。別のブラウザで試してみて下さい。',
      });
    }
  };
  const handleOnCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertProps({
      open: false,
    });
  };
  const handleSelection = (obj: any, canvasData: fabric.Canvas) => {
    const activeObjs: any = canvasData.getActiveObjects();
    if (activeObjs.length === 1) {
      const originText: string = activeObjs[0].text;
      const replaceText = originText.replace(/\n/g, "");
      setNaireText(replaceText);
      setModifyNaireTextData({
        fontSize: activeObjs[0].fontSize,
        top: activeObjs[0].top,
        left: activeObjs[0].left,
        scaleX: activeObjs[0].scaleX,
        scaleY: activeObjs[0].scaleY,
      });
    } else {
      setNaireText('');
      setModifyNaireTextData({});
    }
  }

  useEffect(() => {

    fabric.Object.prototype.borderColor = '#ad8522';
    fabric.Object.prototype.cornerStrokeColor = '#ad8522';

    const canvas = new fabric.Canvas(canvasEl.current, {
      backgroundColor: "#fff",
    });
    const canvasOnEvent = [
      'selection:created',
      'selection:updated',
      'selection:cleared',
      'object:modified',
    ];
    const canvasParentSizeChange = () => {
      if (!canvasParentEl.current) return;
      const width = canvasParentEl.current.clientWidth;
      const height = width / Math.sqrt(2);
      canvasParentEl.current.style.height = `${height}px`;
    }
    const canvasSizeChange = () => {
      canvas.setWidth(canvasParentEl.current?.clientWidth || 0);
      canvas.setHeight(canvasParentEl.current?.clientHeight || 0);
      canvas.renderAll();
    }
    const handleResizeCanvas = () => {
      canvasParentSizeChange();
      canvasSizeChange();
      setCanvasData(canvas);
    }

    setDpi(300);
    setNoshiYoutData([...initNoshiData]);

    canvasOnEvent.forEach((elem) => {
      canvas.on(elem, (object) => handleSelection(object, canvas));
    });
    handleResizeCanvas();
    window.addEventListener('resize', handleResizeCanvas, false);

    return () => {
      canvas.dispose();
      window.removeEventListener('resize', handleResizeCanvas)
    }

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Box
        my={{ xs: 2.5, sm: 3, md: 3.5, }}
        mx={'auto'}
        sx={{
          maxWidth: '1020px',
        }}
      >
        <Alert severity="info" sx={{ '& .MuiAlert-message br': { display: { xs: 'none', md: 'inline', } } }}>
          <AlertTitle sx={{ fontWeight: "bold" }}>のしを作成しましょう。</AlertTitle>
          のしかけジェネレーターは、のし紙・掛け紙を無料で作成できるWebサービスです<br />
          以下の設問に順に回答していくと、熨斗（のし）や水引を印刷した、のし紙・掛け紙を簡単に作る事ができます。<br />
          ご進物や贈答品にご利用下さい。
        </Alert>
      </Box>
      <Box
        my={{ xs: 2.5, sm: 3, md: 3.5, }}
        mx={'auto'}
        sx={{
          maxWidth: '840px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              component="p"
              mb={1.5}
              fontWeight="bold"
              sx={{
                fontSize: { xs: "0.875rem", md: "1rem" },
                lineHeight: { xs: "1.5", md: "1.75" },
                color: 'text.secondary',
              }}
            >
              どのような用途でお使いになりますか？
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="youto">用途を選択してください</InputLabel>
              <Select
                labelId="youto"
                label="用途を選択してください"
                value={noshiYoutId}
                onChange={(event) => handleOnChangeNoshiYouto(event)}
              >
                <MenuItem value="">未選択</MenuItem>
                {noshiYoutData.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>{item.label}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              mb={1.5}
              fontWeight="bold"
              sx={{
                fontSize: { xs: "0.875rem", md: "1rem" },
                lineHeight: { xs: "1.5", md: "1.75" },
                color: 'text.secondary',
              }}
            >
              表書きは何にしますか？
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="omotegaki">表書きを選択してください</InputLabel>
              <Select
                labelId="omotegaki"
                label="表書きを選択してください"
                value={noshiOmotegakiId}
                onChange={(event) => handleOnChangeNoshiOmotegaki(event)}
              >
                <MenuItem value="">未選択</MenuItem>
                {noshiOmotegakiData.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>{item.label}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography
              component="p"
              mb={1.5}
              fontWeight="bold"
              sx={{
                fontSize: { xs: "0.875rem", md: "1rem" },
                lineHeight: { xs: "1.5", md: "1.75" },
                color: 'text.secondary',
              }}
            >
              サイズはどうしますか？
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="size">サイズを選択してください</InputLabel>
              <Select
                labelId="size"
                label="サイズを選択してください"
                value={noshiSizeId}
                onChange={(event) => handleOnChangeNoshiSize(event)}
              >
                <MenuItem value="">未選択</MenuItem>
                {noshiSizeData.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.id === 'size_b4' && 'B4判'}
                      {item.id === 'size_a4' && 'A4判'}
                      {item.id === 'size_b5' && 'B5判'}
                      {item.id === 'size_a5' && 'A5判'}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          {naireFlag && (
            <Grid item xs={12}>
              <Box
                mt={3}
                mb={1.5}
              >
                <Alert severity="success">
                  <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>画像のダウンロードが可能になりました。</AlertTitle>
                  基本設定は完了しました。<br />このまま下の「画像ダウンロード」ボタンを押して、のし紙・掛け紙をご利用いただく事も可能です。<br />あるいは表書きの位置や大きさを調整し、名入れをおこなって完成度の高い、のし紙・掛け紙に仕上げる事も可能です。
                </Alert>
              </Box>
              <Typography
                component="p"
                mb={1.5}
                fontWeight="bold"
                sx={{
                  fontSize: { xs: "0.875rem", md: "1rem" },
                  lineHeight: { xs: "1.5", md: "1.75" },
                  color: 'text.secondary',
                }}
              >
                {Object.keys(modifyNaireTextData).length ? '文字を修正して「文字修正」ボタンを押して下さい。' : '文字を入力して「文字追加」ボタンを押して下さい。'}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: { xs: "stretch", sm: "flex-start" },
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Box sx={{ flexGrow: 1, }}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="naire">名前等を入力してください</InputLabel>
                    <OutlinedInput
                      id="naire"
                      label="名前等を入力してください"
                      value={naireText}
                      onChange={(event) => setNaireText(event.target.value)}
                    />
                    <FormHelperText sx={{ lineHeight: 1.4, marginTop: 1 }}>文字の追加は複数回おこなえます。</FormHelperText>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    alignSelf: { xs: "flex-end", sm: "auto" },
                    marginTop: { xs: 1, sm: "9px" },
                    marginLeft: { xs: 0, sm: 2 },
                  }}
                >
                  {Object.keys(modifyNaireTextData).length ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={() => handleOnClickModifyNaireText()}
                    >
                      文字修正
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => handleOnClickAddNaireText()}
                    >
                      文字追加
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box
        mt={{ xs: 5, sm: 6, md: 7, }}
        mb={{ xs: 2.5, sm: 3, md: 3.5, }}
        mx={'auto'}
        sx={{
          maxWidth: '840px',
          position: "relative",
        }}
      >
        <Stack
          direction={{ xs: 'column', md: "row", }}
          spacing={0.5}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 2,
            padding: 1,
          }}
        >
          <Tooltip title="選択した文字の削除">
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleOnClickSelectedDelete()}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="選択した文字の中央揃え">
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleOnClickSelectedCenter()}
            >
              <AlignHorizontalCenterIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="選択した文字の上揃え">
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleOnClickAlignment('top')}
            >
              <AlignVerticalTopIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="選択した文字の下揃え">
            <IconButton
              color="primary"
              size="small"
              onClick={() => handleOnClickAlignment('bottom')}
            >
              <AlignVerticalBottomIcon />
            </IconButton>
          </Tooltip>
        </Stack>
        <Box
          sx={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: 'grey.400',
          }}
        >
          <Box
            ref={canvasParentEl}
            sx={{
              width: '100%',
              overflow: 'hidden',
              position: "relative",
              zIndex: 1,
            }}
          >
            <canvas ref={canvasEl} />
          </Box>
        </Box>
        <Typography
          variant='caption'
          component={'p'}
          sx={{
            mt: 0.5,
            lineHeight: 1.5,
            display: 'flex',
            '&::before': {
              content: '"※"',
              flexShrink: 0,
              mr: 0.25,
            }
          }}
        >
          ログイン後、サイト名とサイトURLが記載されていない、のし紙・掛け紙をダウンロードできます。
        </Typography>
      </Box>
      <Box
        my={{ xs: 2.5, sm: 3, md: 3.5, }}
        mx={'auto'}
        sx={{
          maxWidth: '840px',
        }}
      >
        {naireFlag ? (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleOnClickImageDownload()}
              disableElevation
            >
              画像ダウンロード
            </Button>
          </Stack>
        ) : (
          <Alert severity="warning" sx={{ '& .MuiAlert-message br': { display: { xs: 'none', md: 'inline', } } }}>
            設定を終えるまでは、のし紙・掛け紙のダウンロードは実行できません。<br />各種設定後、こちらにダウンロードボタンが表示されます。
          </Alert>
        )}
      </Box>
      <Snackbar
        open={alertProps.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleOnCloseAlert}
        sx={{ bottom: 50, }}
      >
        <Alert
          severity={alertProps.type}
          onClose={handleOnCloseAlert}
          sx={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: `${alertProps.type}.main`,
          }}
        >
          {alertProps.title && <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>{alertProps.title}</AlertTitle>}
          {alertProps.message}
        </Alert>
      </Snackbar>
      <iframe src="about:blank" ref={iframeRef} title="download" style={{ display: "none", }} />
    </React.Fragment>
  );

};

export default Noshi;