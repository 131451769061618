import React from 'react';
import { signOut } from "firebase/auth";
import { useNavigate, useLocation, NavLink } from 'react-router-dom';

import { auth } from './firebase';
import { useAuthContext } from './auth';
import SubMenu from '../component/SubMenu';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

function DrawerContents() {

  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useAuthContext();
  const handleOnClickLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/signin');
      }).catch((error) => {
        console.log(error);
      });
  };
  const menuList = [
    { label: 'ホーム', url: '/', icon: <HomeOutlinedIcon /> },
  ];

  return (
    <React.Fragment>
      <Box>
        {authContext?.isSignedIn ? (
          <React.Fragment>
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar
                src={`${authContext.isSignedIn.photoURL}`}
                alt={`${authContext.isSignedIn.displayName}`}
              />
              <Box
                sx={{
                  pl: 2,
                  wordBreak: 'break-all',
                  width: 'calc(100% - 40px)',
                }}>
                <Typography component='p' variant="subtitle2">{authContext.isSignedIn.displayName}</Typography>
                <Typography
                  component='p'
                  variant="caption"
                  sx={{
                    lineHeight: '1.2',
                    color: 'grey.600',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {authContext.isSignedIn.email}
                </Typography>
              </Box>
            </Box>
            <Divider />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar>G</Avatar>
              <Box sx={{ ml: 2, wordBreak: 'break-all', }}>
                <Typography component='p' variant="subtitle2">ゲストさん</Typography>
              </Box>
            </Box>
            <Divider />
          </React.Fragment>
        )}
        <List component='div'>
          {authContext?.isSignedIn ? (
            <ListItemButton onClick={handleOnClickLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary='ログアウト' />
            </ListItemButton>
          ) : (
            <ListItemButton component={NavLink} to='/signin'>
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary='ログイン' />
            </ListItemButton>
          )}
        </List>
        <Divider />
      </Box>
      <List component='nav'>
        {menuList.map((item, index) => {
          return (
            <ListItemButton
              component={NavLink}
              to={item.url}
              selected={item.url === location.pathname}
              key={index}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          )
        })}
      </List>
      <Box component="span" sx={{ flexGrow: 1 }} />
      <Divider />
      <Stack
        direction="column"
        spacing={1}
        px={2}
        py={2}
      >
        <SubMenu />
      </Stack>
    </React.Fragment>
  );

};

export default DrawerContents;
