import SubMenu from '../component/SubMenu';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function FooterContents() {

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: { xs: 'none', md: 'block', },
        }}
      >
        <SubMenu />
      </Stack>
      <Box component="span" sx={{ flexGrow: 1, display: { xs: 'none', md: 'block', } }} />
      <Typography>
        <Typography
          component='small'
          sx={{
            fontSize: '10px',
            fontFamily: [
              '"Helvetica Neue"',
              'Arial',
              '"Hiragino Kaku Gothic ProN"',
              '"Hiragino Sans"',
              'Meiryo',
              'sans-serif',
            ].join(','),
            color: 'text.secondary',
            lineHeight: 1.4,
            display: 'block'
          }}
        >
          Copyright © 2022 のしかけジェネレーター All Rights Reserved.
        </Typography>
      </Typography>
    </Box>
  );

};

export default FooterContents;