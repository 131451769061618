import { useState } from 'react';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';

import DrawerContents from './DrawerContents';
import HeaderContents from './HeaderContents';
import FooterContents from './FooterContents';

type LayoutProps = {
  children?: React.ReactNode
}

function Layout(props: LayoutProps) {

  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;
  const handleOnClickDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: 'flex', }}>
      <AppBar
        elevation={0}
        position="fixed"
        color='default'
        sx={{
          backgroundColor: '#fff',
          '&::after': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '1px',
            backgroundColor: 'divider',
            position: 'absolute',
            bottom: 0,
            left: 0,
          },
        }}
      >
        <Toolbar sx={{ minHeight: { xs: '48px', md: '64px', } }}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleOnClickDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' }, }}
          >
            <MenuIcon />
          </IconButton>
          <HeaderContents />
        </Toolbar>
      </AppBar>
      <Box
        component="aside"
        sx={{
          // width: { md: drawerWidth, },
          // flexShrink: { md: 0, },
          zIndex: 1,
        }}
      >
        <Drawer
          open={mobileOpen}
          onClose={handleOnClickDrawerToggle}
          ModalProps={{ keepMounted: true, }}
          sx={{ display: { xs: 'block', md: 'none', }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, }, }}
        >
          <DrawerContents />
        </Drawer>
        {/* <Drawer
          variant="permanent"
          sx={{ display: { xs: 'none', md: 'block' }, '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, }, }}
          open
        >
          <Toolbar sx={{ minHeight: { xs: '48px', md: '64px', } }} />
          <DrawerContents />
        </Drawer> */}
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          px: { xs: 2, sm: 3, md: 4 },
          boxSizing: 'border-box',
          backgroundColor: '#f0ebdf',
        }}
      >
        <Toolbar sx={{ px: { xs: 0, }, minHeight: { xs: '48px', md: '64px', } }} />
        <Box
          component="main"
        >
          {props.children}
        </Box>
        <Box
          component="footer"
          sx={{
            display: 'flex',
            borderTop: '1px solid',
            borderColor: 'divider',
            height: '60px',
            px: { xs: 2, sm: 3, md: 4 },
            mx: { xs: -2, sm: -3, md: -4 },
          }}
        >
          <FooterContents />
        </Box>
      </Box>
    </Box>
  );

};

export default Layout;
