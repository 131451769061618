import { createContext, useState, useEffect, useContext } from 'react';
import type { User } from '@firebase/auth'
import { onAuthStateChanged } from "firebase/auth";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { auth } from './firebase';

type AuthUserState = User | null | undefined;
type AuthContextInterface = {
  isSignedIn: AuthUserState;
  isLoading: boolean;
}
type AuthProviderProps = {
  children?: React.ReactNode
}

const AuthContext = createContext<AuthContextInterface | null>(null);
const useAuthContext = () => useContext(AuthContext);

function AuthProvider(props: AuthProviderProps) {
  
  const initialState: AuthUserState = undefined;
  const [isSignedIn, setIsSignedIn] = useState<AuthUserState>(initialState);
  const [isLoading, setIsLoading] = useState(true);
  const value = {
    isSignedIn,
    isLoading,
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsSignedIn(user);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && props.children}
    </AuthContext.Provider>
  );

};

export { useAuthContext };
export default AuthProvider;