import { Helmet, HelmetProvider } from 'react-helmet-async';

type Props = {
  pageTitle: string,
  pageDesc?: string,
  pageUrl: string
}

function Head({ pageTitle, pageDesc, pageUrl }: Props) {

  return (
    <HelmetProvider>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDesc} />
        <link rel="canonical" href={pageUrl} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDesc} />
      </Helmet>
    </HelmetProvider>
  );

};

export default Head;