import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Head from "../component/Head";
import Layout from '../component/Layout';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

//AboutContents
type AboutContentsProps = {
  location: {
    pathname: string;
  };
  children?: React.ReactNode
}

const AboutContents = (props: AboutContentsProps) => {

  const pageUrl = process.env.REACT_APP_APP_URL;
  const location = props.location;

  return (
    <React.Fragment>
      <Head
        pageTitle="ご利用について | のし紙・掛け紙の作成サイト のしかけジェネレーター"
        pageUrl={`${pageUrl}${location.pathname}`}
      />
      <Layout>
        <Box
          sx={{
            height: { xs: "181px", sm: "205px", md: "244px", },
            backgroundColor: '#f7f4ec',
            mx: { xs: -2, sm: -3, md: -4, },
            mb: { xs: "-181px", sm: "-205px", md: "-244px", },
            position: "relative",
            zIndex: 1,
          }}
        />
        <Container
          component={'section'}
          sx={{
            minHeight: { xs: 'calc(100vh - 48px - 60px)', md: 'calc(100vh - 64px - 60px)', },
            px: { xs: 0, },
            py: { xs: 5, sm: 6, md: 7, },
            position: "relative",
            zIndex: 2,
          }}
        >
          <Box
            mx={'auto'}
            sx={{
              px: { xs: 2, sm: 3, md: 4 },
              maxWidth: "1084px",
              position: "relative",
              zIndex: 1,
            }}
          >
            <Typography
              component="p"
              variant="h4"
              sx={{
                color: "primary.main",
                fontFamily: "'Lora', serif",
                fontSize: { xs: "1.75rem", md: "2.5rem", },
                fontWeight: 300,
                letterSpacing: "0.0125em",
                position: "relative",
                zIndex: 3,
              }}
            >
              About
            </Typography>
            <Typography
              component="h2"
              mt={1}
              sx={{
                color: "text.secondary",
                fontSize: "0.75rem",
                letterSpacing: "0.05em",
                fontFeatureSettings: "'palt'",
                position: "relative",
                zIndex: 3,
              }}
            >
              ご利用について
            </Typography>
          </Box>
          <Paper
            sx={{
              px: { xs: 2, sm: 3, md: 4 },
              py: { xs: 2.5, sm: 3, md: 3.5, },
              my: { xs: 5, sm: 6, md: 7, },
              position: "relative",
              zIndex: 2,
            }}
          >
            <Box
              my={{ xs: 2.5, sm: 3, md: 3.5, }}
              mx={'auto'}
              sx={{
                maxWidth: '1020px',
              }}
            >
              <Typography>のしかけジェネレーター（以下「当サイト」）は、慶事や弔事の贈り物に用いる、のし紙・掛け紙をWeb上で作成していただけるサービスです。文字の位置やサイズを自由に設定していただく事が可能で、表書きや名入れの記載をWeb上で簡単に行うことができます。Web上で作成した、のし紙・掛け紙は、印刷サイズに応じた画像データ（png）として無料でダウンロードしていただけます。</Typography>
              <Typography mt={3}>当サイトをご利用いただくにあたり、以下の項目をご一読いただき、ご承知いただいたうえでご利用下さい。当サイトのご利用に際しては、以下の項目にご同意されたものとさせていただきます。なお、以下の項目は予告なしに変更されることがあります。あらかじめご了承ください。</Typography>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  mt={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    生成コンテンツのご利用について
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Typography>当サイトより作成した、生成コンテンツ（のし紙・掛け紙画像）は、個人･法人･商用･非商用を問わず無料でダウンロードし、印刷してご利用いただけます。ただし、生成コンテンツ自体をそのまま、あるいは改変して商品として販売、再配布する行為については、ご利用をお断りしております。</Typography>
                </Box>
              </Box>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    著作権について
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Typography>当サイトに掲載している全てのコンテンツに関する著作権は、生成コンテンツも含め、当サイトの管理者もしくは当方が利用許諾を得た第三者に帰属しております。ご利用に際しては、著作権者の許可なく複製、公衆送信、改変、転用、販売等といった、権利を侵害する行為をおこなわない旨に同意したものとさせていただきます。</Typography>
                </Box>
              </Box>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    免責事項
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Typography>当サイトのコンテンツについては万全を期しておりますが、内容等の正確性、有用性、安全性に関して保証するものではありません。生成コンテンツのダウンロードにより生じたコンピューター機器への損害等に関しては、いかなる責任も負いかねます。また、当サイトは予告なしに運営を中断あるいは終了、掲載内容を修正、変更、削除する場合があります。それによって生じた損害についても責任を負いかねますので御了承ください。</Typography>
                </Box>
              </Box>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    リンクについて
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Typography>当サイトへのリンクは自由におこなっていただいて結構です。 ただし、公序良俗に反するサイト等からのリンクはご遠慮願います。また、当サイトからのリンク先、あるいは当サイトへのリンク元となる第三者のウェブサイトについては、その内容を推奨、保証するものではなく、第三者のウェブサイト利用によって生じた損害については責任を負いかねますので御了承ください。</Typography>
                </Box>
              </Box>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    推奨環境
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Typography>当サイトのご利用にあたっては、下記のOS、ブラウザでの閲覧を推奨しております。また、当サイトはJavaScriptフレームワーク、Reactを使用して作成されております。JavaScriptの設定が無効になっている場合は正しくページを表示できませんので、設定を有効にして閲覧して下さい。</Typography>
                  <Box component={'dl'} my={3}>
                    <Typography
                      component={'dt'}
                      mb={1}
                      sx={{
                        fontWeight: 'fontWeightBold',
                      }}
                    >
                      デスクトップ
                    </Typography>
                    <Typography component={'dd'}>
                      <Box
                        component={'dl'}
                        my={0.5}
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row', },
                        }}
                      >
                        <Typography
                          component={'dt'}
                          sx={{
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: { xs: 'auto', sm: '5.5em', },
                            fontSize: '0.875rem',
                            fontWeight: 'fontWeightBold',
                            color: 'text.secondary',
                            '&::after': {
                              content: '"："',
                              display: { xs: 'none', sm: 'block', },
                              width: '1em',
                              textAlign: 'center',
                            }
                          }}
                        >
                          OS
                        </Typography>
                        <Typography component={'dd'} sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', }, }}>Windows 11 〜 / macOS 12 〜</Typography>
                      </Box>
                      <Box
                        component={'dl'}
                        my={0.5}
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row', },
                        }}
                      >
                        <Typography
                          component={'dt'}
                          sx={{
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: { xs: 'auto', sm: '5.5em', },
                            fontSize: '0.875rem',
                            fontWeight: 'fontWeightBold',
                            color: 'text.secondary',
                            '&::after': {
                              content: '"："',
                              display: { xs: 'none', sm: 'block', },
                              width: '1em',
                              textAlign: 'center',
                            }
                          }}
                        >
                          ブラウザ
                        </Typography>
                        <Typography component={'dd'} sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', }, }}>Microsoft Edge（最新版） / Firefox（最新版） / Google Chrome（最新版） / Safari（最新版）</Typography>
                      </Box>
                    </Typography>
                  </Box>
                  <Box component={'dl'} my={3}>
                    <Typography
                      component={'dt'}
                      mb={1}
                      sx={{
                        fontWeight: 'fontWeightBold',
                      }}
                    >
                      タブレット
                    </Typography>
                    <Typography component={'dd'}>
                      <Box
                        component={'dl'}
                        my={0.5}
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row', },
                        }}
                      >
                        <Typography
                          component={'dt'}
                          sx={{
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: { xs: 'auto', sm: '5.5em', },
                            fontSize: '0.875rem',
                            fontWeight: 'fontWeightBold',
                            color: 'text.secondary',
                            '&::after': {
                              content: '"："',
                              display: { xs: 'none', sm: 'block', },
                              width: '1em',
                              textAlign: 'center',
                            }
                          }}
                        >
                          OS
                        </Typography>
                        <Typography component={'dd'} sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', }, }}>iPadOS 15 〜</Typography>
                      </Box>
                      <Box
                        component={'dl'}
                        my={0.5}
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row', },
                        }}
                      >
                        <Typography
                          component={'dt'}
                          sx={{
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: { xs: 'auto', sm: '5.5em', },
                            fontSize: '0.875rem',
                            fontWeight: 'fontWeightBold',
                            color: 'text.secondary',
                            '&::after': {
                              content: '"："',
                              display: { xs: 'none', sm: 'block', },
                              width: '1em',
                              textAlign: 'center',
                            }
                          }}
                        >
                          ブラウザ
                        </Typography>
                        <Typography component={'dd'} sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', }, }}>Safari（最新版）</Typography>
                      </Box>
                    </Typography>
                  </Box>
                  <Box component={'dl'} my={3}>
                    <Typography
                      component={'dt'}
                      mb={1}
                      sx={{
                        fontWeight: 'fontWeightBold',
                      }}
                    >
                      スマートフォン
                    </Typography>
                    <Typography component={'dd'}>
                      <Box
                        component={'dl'}
                        my={0.5}
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row', },
                        }}
                      >
                        <Typography
                          component={'dt'}
                          sx={{
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: { xs: 'auto', sm: '5.5em', },
                            fontSize: '0.875rem',
                            fontWeight: 'fontWeightBold',
                            color: 'text.secondary',
                            '&::after': {
                              content: '"："',
                              display: { xs: 'none', sm: 'block', },
                              width: '1em',
                              textAlign: 'center',
                            }
                          }}
                        >
                          OS
                        </Typography>
                        <Typography component={'dd'} sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', }, }}>Android 12 〜 / iOS 16 〜</Typography>
                      </Box>
                      <Box
                        component={'dl'}
                        my={0.5}
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row', },
                        }}
                      >
                        <Typography
                          component={'dt'}
                          sx={{
                            flexShrink: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: { xs: 'auto', sm: '5.5em', },
                            fontSize: '0.875rem',
                            fontWeight: 'fontWeightBold',
                            color: 'text.secondary',
                            '&::after': {
                              content: '"："',
                              display: { xs: 'none', sm: 'block', },
                              width: '1em',
                              textAlign: 'center',
                            }
                          }}
                        >
                          ブラウザ
                        </Typography>
                        <Typography component={'dd'} sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', }, }}>Safari（最新版） / Google Chrome（最新版）</Typography>
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Layout>
    </React.Fragment>
  );

}

//About
type AboutProps = {
  children?: React.ReactNode
}

function About(props: AboutProps) {

  const location = useLocation();

  //トレイリングスラッシュ（末尾のスラッシュ）の削除
  if (location.pathname.slice(-1) === '/') return <Navigate to={location.pathname.slice(0, -1)} />
  
  return (
    <AboutContents location={location}>
      {props.children}
    </AboutContents>
  );

}

export default About;
