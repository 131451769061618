import musubikiriKouhakuNoshiari from '../image/musubikiri_kouhaku_noshiari.svg'
import musubikiriKouhakuNoshinashi from '../image/musubikiri_kouhaku_noshinashi.svg'
import musubikiriShirokuro from '../image/musubikiri_shirokuro.svg'
import choumusubiKouhakuNoshiari from '../image/choumusubi_kouhaku_noshiari.svg'

export const noshiData = [
    {
      id: 'youto_kekkonniwai',
      label: '結婚祝い',
      data: [
        {
          id: 'omotegaki_kotobuki_1',
          label: '寿',
          mizuhiki: musubikiriKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_kotobuki_2',
          label: '壽',
          mizuhiki: musubikiriKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_gokekkonnoiwai',
          label: '御結婚御祝',
          mizuhiki: musubikiriKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_kekkonnuchiiwai',
          label: '結婚内祝',
          mizuhiki: musubikiriKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
      ]
    },
    {
      id: 'youto_syussanniwai',
      label: '出産祝い',
      data: [
        {
          id: 'omotegaki_syussannoiwai',
          label: '出産御祝',
          mizuhiki: choumusubiKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_syussannuchiiwai',
          label: '出産内祝',
          mizuhiki: choumusubiKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
      ]
    },
    {
      id: 'youto_oiwai',
      label: 'お祝い',
      data: [
        {
          id: 'omotegaki_oiwai',
          label: '御祝',
          mizuhiki: choumusubiKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_uchiiwai',
          label: '内祝',
          mizuhiki: choumusubiKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
      ]
    },
    {
      id: 'youto_goaisatu',
      label: 'ご挨拶',
      data: [
        {
          id: 'omotegaki_onennga',
          label: '御年賀',
          mizuhiki: choumusubiKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_otyuugenn',
          label: '御中元',
          mizuhiki: choumusubiKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_oseibo',
          label: '御歳暮',
          mizuhiki: choumusubiKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_kinennhinn',
          label: '記念品',
          mizuhiki: choumusubiKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_sosina',
          label: '粗品',
          mizuhiki: choumusubiKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_orei',
          label: '御礼',
          mizuhiki: choumusubiKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
      ]
    },
    {
      id: 'youto_omimai',
      label: 'お見舞い',
      data: [
        {
          id: 'omotegaki_omimai',
          label: '御見舞',
          mizuhiki: musubikiriKouhakuNoshinashi,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_kaikiiwai',
          label: '快気祝',
          mizuhiki: musubikiriKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_kaikiuchiiwai',
          label: '快気内祝',
          mizuhiki: musubikiriKouhakuNoshiari,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
      ]
    },
    {
      id: 'youto_chouji',
      label: '弔事',
      data: [
        {
          id: 'omotegaki_kokotozasi',
          label: '志',
          mizuhiki: musubikiriShirokuro,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
        {
          id: 'omotegaki_gokuu',
          label: '御供',
          mizuhiki: musubikiriShirokuro,
          data: [
            { id: 'size_b4', width: 14.3307, },
            { id: 'size_a4', width: 11.6929, },
            { id: 'size_b5', width: 10.1181, },
            { id: 'size_a5', width: 8.2677, },
          ],
        },
      ]
    }
  ];