import { Routes, Route } from "react-router-dom";

import AuthProvider from './component/auth';
import ScrollToTop from './component/scrollToTop';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignInLink from './pages/SignInLink';
import About from './pages/About';
import Privacy from './pages/Privacy';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import nsjpWoff2 from './fonts/nsjp-b-v.woff2';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

declare module '@mui/material/styles' {
  interface Palette {
    google: Palette['primary'];
  }
  interface PaletteOptions {
    google?: PaletteOptions['primary'];
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    google: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#ad8522',
    },
    secondary: {
      main: '#b9040a',
    },
    error: {
      main: '#e00000',
    },
    warning: {
      main: '#e0b500',
    },
    info: {
      main: '#677fc1',
    },
    success: {
      main: '#5da06d',
    },
    google: {
      main: '#757575',
    },
  },
  typography: {
    fontFamily: [
      '"Hiragino Kaku Gothic ProN"',
      '"Hiragino Sans"',
      'Meiryo',
      'sans-serif',
    ].join(','),
    button: {
      fontWeight: 700,
      textTransform: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'nsjpbv';
          font-style: bold;
          font-display: swap;
          font-weight: 700;
          src: url(${nsjpWoff2}) format('woff2');
        }
      `,
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signinlink" element={<SignInLink />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );

}

export default App;
