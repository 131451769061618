import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Head from "../component/Head";
import Layout from '../component/Layout';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

//AboutContents
type AboutContentsProps = {
  location: {
    pathname: string;
  };
  children?: React.ReactNode
}

const AboutContents = (props: AboutContentsProps) => {

  const pageUrl = process.env.REACT_APP_APP_URL;
  const location = props.location;

  return (
    <React.Fragment>
      <Head
        pageTitle="プライバシーポリシー | のし紙・掛け紙の作成サイト のしかけジェネレーター"
        pageUrl={`${pageUrl}${location.pathname}`}
      />
      <Layout>
        <Box
          sx={{
            height: { xs: "181px", sm: "205px", md: "244px", },
            backgroundColor: '#f7f4ec',
            mx: { xs: -2, sm: -3, md: -4, },
            mb: { xs: "-181px", sm: "-205px", md: "-244px", },
            position: "relative",
            zIndex: 1,
          }}
        />
        <Container
          component={'section'}
          sx={{
            minHeight: { xs: 'calc(100vh - 48px - 60px)', md: 'calc(100vh - 64px - 60px)', },
            px: { xs: 0, },
            py: { xs: 5, sm: 6, md: 7, },
            position: "relative",
            zIndex: 2,
          }}
        >
          <Box
            mx={'auto'}
            sx={{
              px: { xs: 2, sm: 3, md: 4 },
              maxWidth: "1084px",
              position: "relative",
              zIndex: 1,
            }}
          >
            <Typography
              component="p"
              variant="h4"
              sx={{
                color: "primary.main",
                fontFamily: "'Lora', serif",
                fontSize: { xs: "1.75rem", md: "2.5rem", },
                fontWeight: 300,
                letterSpacing: "0.0125em",
                position: "relative",
                zIndex: 3,
              }}
            >
              Privacy Policy
            </Typography>
            <Typography
              component="h2"
              mt={1}
              sx={{
                color: "text.secondary",
                fontSize: "0.75rem",
                letterSpacing: "0.05em",
                fontFeatureSettings: "'palt'",
                position: "relative",
                zIndex: 3,
              }}
            >
              プライバシーポリシー
            </Typography>
          </Box>
          <Paper
            sx={{
              px: { xs: 2, sm: 3, md: 4 },
              py: { xs: 2.5, sm: 3, md: 3.5, },
              my: { xs: 5, sm: 6, md: 7, },
              position: "relative",
              zIndex: 2,
            }}
          >
            <Box
              my={{ xs: 2.5, sm: 3, md: 3.5, }}
              mx={'auto'}
              sx={{
                maxWidth: '1020px',
              }}
            >
              <Typography>のしかけジェネレーター（以下「当サイト」）では、ユーザーの個人情報の取扱いについて以下のとおりプライバシーポリシー（以下「本ポリシー」）を定め、個人情報の保護と適正な取り扱いに努めます。</Typography>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  mt={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    個人情報とは
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Typography>個人情報とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名・生年月日・住所・電話番号・連絡先・その他の記述等によって、特定の個人を識別できる情報（個人識別情報）を指します。</Typography>
                </Box>
              </Box>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    個人情報の取得
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Typography>当サイトでは、サービスのご利用にあたって個人情報が必要となることがあり、その際は必要な範囲で適切な手段によって、氏名、メールアドレス等の個人情報を取得させていただきます。</Typography>
                </Box>
              </Box>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    個人情報の利用目的
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Typography>当サイトがユーザーから取得した個人情報を利用する目的は以下のとおりです。</Typography>
                  <Box
                    component={'ul'}
                    sx={{
                      mt: 3,
                      mb: 0,
                      p: 0,
                    }}
                  >
                    <Typography
                      component={'li'}
                      sx={{
                        listStyle: 'none',
                        display: 'flex',
                        '&::before': {
                          content: '"・"',
                          flexShrink: 0,
                          mr: 0.5,
                        }
                      }}
                    >
                      お問い合わせやご質問に対して回答する際の電子メール送付
                    </Typography>
                    <Typography
                      component={'li'}
                      sx={{
                        listStyle: 'none',
                        display: 'flex',
                        '&::before': {
                          content: '"・"',
                          flexShrink: 0,
                          mr: 0.5,
                        }
                      }}
                    >
                      当サイトの提供サービス利用時のログイン認証
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    個人情報の第三者提供
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Typography>当サイトは、ユーザーより取得した個人情報を適切に管理し、以下のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。</Typography>
                  <Box
                    component={'ul'}
                    sx={{
                      mt: 3,
                      mb: 0,
                      p: 0,
                    }}
                  >
                    <Typography
                      component={'li'}
                      sx={{
                        listStyle: 'none',
                        display: 'flex',
                        '&::before': {
                          content: '"・"',
                          flexShrink: 0,
                          mr: 0.5,
                        }
                      }}
                    >
                      法令に基づき開示することが必要である場合
                    </Typography>
                    <Typography
                      component={'li'}
                      sx={{
                        listStyle: 'none',
                        display: 'flex',
                        '&::before': {
                          content: '"・"',
                          flexShrink: 0,
                          mr: 0.5,
                        }
                      }}
                    >
                      あらかじめ本人の同意を得ている場合
                    </Typography>
                    <Typography
                      component={'li'}
                      sx={{
                        listStyle: 'none',
                        display: 'flex',
                        '&::before': {
                          content: '"・"',
                          flexShrink: 0,
                          mr: 0.5,
                        }
                      }}
                    >
                      人の生命・身体または財産の保護のために必要がある場合があり、かつ本人の同意を得ることが困難であると判断した場合
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    お問い合わせ窓口
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 2.5, sm: 3, md: 3.5, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Typography>本ポリシーに関するお問い合わせは、下記までご連絡ください。</Typography>
                  <Typography mt={3}>のしかけジェネレーター<br/>Mail : contact@noshikake-generator.app</Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Layout>
    </React.Fragment>
  );

}

//About
type AboutProps = {
  children?: React.ReactNode
}

function About(props: AboutProps) {

  const location = useLocation();

  //トレイリングスラッシュ（末尾のスラッシュ）の削除
  if (location.pathname.slice(-1) === '/') return <Navigate to={location.pathname.slice(0, -1)} />

  return (
    <AboutContents location={location}>
      {props.children}
    </AboutContents>
  );

}

export default About;
