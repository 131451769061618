import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { httpsCallable } from "firebase/functions";

import Head from "../component/Head";
import Layout from '../component/Layout';
import { functions } from '../component/firebase';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

//ContactContents
type ContactContentsProps = {
  location: {
    pathname: string;
  };
  children?: React.ReactNode
}
type FormElemProps = {
  value: string,
  error?: boolean,
  helperText?: string,
}
type AlertProps = {
  open: boolean,
  type?: "success" | "info" | "warning" | "error",
  title?: string,
  message?: string,
}

const ContactContents = (props: ContactContentsProps) => {

  const pageUrl = process.env.REACT_APP_APP_URL;
  const location = props.location;

  const [nameProps, setNameProps] = useState<FormElemProps>({ value: '', });
  const [emailProps, setEmailProps] = useState<FormElemProps>({ value: '', });
  const [contentsProps, setContentsProps] = useState<FormElemProps>({ value: '', });
  const [alertProps, setAlertProps] = useState<AlertProps>({ open: false, });

  const handleOnChangeTextField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, func: React.Dispatch<React.SetStateAction<FormElemProps>>) => {
    if (event.currentTarget.value.length > 0) {
      func({
        value: event.currentTarget.value,
      });
    } else {
      func({
        value: '',
      });
    }
  };
  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const sendMail = httpsCallable(functions, 'sendMail');
    const sendApiKey = process.env.REACT_APP_SENDGRID_API_KEY;
    let submitFlag = true;
    if (nameProps.value === '') {
      setNameProps({
        value: '',
        error: true,
        helperText: 'お名前を入力して下さい',
      });
      submitFlag = false;
    }
    if (emailProps.value === '') {
      setEmailProps({
        value: '',
        error: true,
        helperText: 'メールアドレスを入力して下さい',
      });
      submitFlag = false;
    }
    if (contentsProps.value === '') {
      setContentsProps({
        value: '',
        error: true,
        helperText: 'お問い合わせ内容を入力して下さい',
      });
      submitFlag = false;
    }
    if (submitFlag) {
      sendMail({
        apiKey: sendApiKey,
        name: nameProps.value,
        email: emailProps.value,
        contents: contentsProps.value,
      })
        .then((result) => {
          setNameProps({ value: '', });
          setEmailProps({ value: '', });
          setContentsProps({ value: '', });
          setAlertProps({
            open: true,
            type: 'success',
            title: 'メールを送信しました。',
            message: '担当より返答させていただきますので、少々お待ちください。',
          });
        })
        .catch((error) => {
          setAlertProps({
            open: true,
            type: 'error',
            title: 'メールを送信できませんでした。',
            message: `${error}`,
          });
        });
    }
  };
  const handleOnCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertProps({
      open: false,
    });
  };

  return (
    <React.Fragment>
      <Head
        pageTitle="お問い合わせ | のし紙・掛け紙の作成サイト のしかけジェネレーター"
        pageUrl={`${pageUrl}${location.pathname}`}
      />
      <Layout>
        <Box
          sx={{
            height: { xs: "181px", sm: "205px", md: "244px", },
            backgroundColor: '#f7f4ec',
            mx: { xs: -2, sm: -3, md: -4, },
            mb: { xs: "-181px", sm: "-205px", md: "-244px", },
            position: "relative",
            zIndex: 1,
          }}
        />
        <Container
          component={'section'}
          sx={{
            minHeight: { xs: 'calc(100vh - 48px - 60px)', md: 'calc(100vh - 64px - 60px)', },
            px: { xs: 0, },
            py: { xs: 5, sm: 6, md: 7, },
            position: "relative",
            zIndex: 2,
          }}
        >
          <Box
            mx={'auto'}
            sx={{
              px: { xs: 2, sm: 3, md: 4 },
              maxWidth: "1084px",
              position: "relative",
              zIndex: 1,
            }}
          >
            <Typography
              component="p"
              variant="h4"
              sx={{
                color: "primary.main",
                fontFamily: "'Lora', serif",
                fontSize: { xs: "1.75rem", md: "2.5rem", },
                fontWeight: 300,
                letterSpacing: "0.0125em",
                position: "relative",
                zIndex: 3,
              }}
            >
              Contact
            </Typography>
            <Typography
              component="h2"
              mt={1}
              ml={0.25}
              sx={{
                color: "text.secondary",
                fontSize: "0.75rem",
                letterSpacing: "0.05em",
                fontFeatureSettings: "'palt'",
                position: "relative",
                zIndex: 3,
              }}
            >
              お問い合わせ
            </Typography>
          </Box>
          <Paper
            sx={{
              px: { xs: 2, sm: 3, md: 4 },
              py: { xs: 2.5, sm: 3, md: 3.5, },
              my: { xs: 5, sm: 6, md: 7, },
              position: "relative",
              zIndex: 2,
            }}
          >
            <Box
              my={{ xs: 2.5, sm: 3, md: 3.5, }}
              mx={'auto'}
              sx={{
                maxWidth: '1020px',
              }}
            >
              <Typography
                sx={{
                  '& br': {
                    display: { xs: 'none', md: 'inline', }
                  }
                }}
              >
                当サイトへのお問い合わせは下記フォームをご利用いただくか、<a href="mailto:contact@noshikake-generator.app" target="_top">contact@noshikake-generator.app</a>までメールにてご連絡ください。<br />近日中に返答させていただきます。
              </Typography>
              <Box
                component={'section'}
                my={{ xs: 5, sm: 6, md: 7, }}
                mx={'auto'}
                sx={{
                  maxWidth: '1020px',
                  position: "relative",
                }}
              >
                <Box
                  px={2}
                  py={1.5}
                  mt={{ xs: 2.5, sm: 3, md: 3.5, }}
                  sx={{
                    backgroundColor: "grey.100",
                    borderLeftColor: "secondary.main",
                    borderLeftWidth: "3px",
                    borderLeftStyle: "solid",
                  }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      color: "text.main",
                      fontSize: { xs: "1.0rem", sm: "1.25rem", },
                      lineHeight: { xs: 1.4, sm: 1.5, },
                      fontWeight: "fontWeightBold",
                    }}
                  >
                    お問い合わせフォーム
                  </Typography>
                </Box>
                <Box
                  my={{ xs: 0, md: 7, }}
                  mx={'auto'}
                  sx={{
                    maxWidth: '840px',
                  }}
                >
                  <Box
                    component="form"
                    noValidate
                    onSubmit={(e) => handleOnSubmit(e)}
                  >
                    <Box my={{ xs: 2, sm: 2.5, md: 3, }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md={4}
                        >
                          <Typography
                            component="p"
                            fontWeight="bold"
                            sx={{
                              fontSize: { xs: "0.875rem", md: "1rem" },
                              lineHeight: { xs: "1.5", md: "1.75" },
                              color: 'text.secondary',
                              mt: { xs: 1, md: 1.75, },
                            }}
                          >
                            お名前
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={8}
                        >
                          <TextField
                            type="text"
                            name="name"
                            label="例：山田太郎"
                            variant="outlined"
                            fullWidth
                            required
                            value={nameProps.value}
                            error={nameProps.error}
                            helperText={nameProps.helperText}
                            onChange={(e) => handleOnChangeTextField(e, setNameProps)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box my={{ xs: 2, sm: 2.5, md: 3, }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md={4}
                        >
                          <Typography
                            component="p"
                            fontWeight="bold"
                            sx={{
                              fontSize: { xs: "0.875rem", md: "1rem" },
                              lineHeight: { xs: "1.5", md: "1.75" },
                              color: 'text.secondary',
                              mt: { xs: 1, md: 1.75, },
                            }}
                          >
                            メールアドレス
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={8}
                        >
                          <TextField
                            type="email"
                            name="email"
                            label="例：tarou.yamada@example.com"
                            variant="outlined"
                            fullWidth
                            required
                            value={emailProps.value}
                            error={emailProps.error}
                            helperText={emailProps.helperText}
                            onChange={(e) => handleOnChangeTextField(e, setEmailProps)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box my={{ xs: 2, sm: 2.5, md: 3, }}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md={4}
                        >
                          <Typography
                            component="p"
                            fontWeight="bold"
                            sx={{
                              fontSize: { xs: "0.875rem", md: "1rem" },
                              lineHeight: { xs: "1.5", md: "1.75" },
                              color: 'text.secondary',
                              mt: { xs: 1, md: 1.75, },
                            }}
                          >
                            お問い合わせ内容
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={8}
                        >
                          <TextField
                            name="contents"
                            variant="outlined"
                            fullWidth
                            required
                            value={contentsProps.value}
                            error={contentsProps.error}
                            helperText={contentsProps.helperText}
                            multiline={true}
                            minRows={12}
                            onChange={(e) => handleOnChangeTextField(e, setContentsProps)}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box my={{ xs: 5, sm: 5.5, md: 6, }}>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          disableElevation
                        >
                          送信する
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Layout>
      <Snackbar
        open={alertProps.open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={handleOnCloseAlert}
        sx={{ bottom: 50, }}
      >
        <Alert
          severity={alertProps.type}
          onClose={handleOnCloseAlert}
          sx={{
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: `${alertProps.type}.main`,
          }}
        >
          {alertProps.title && <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>{alertProps.title}</AlertTitle>}
          {alertProps.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );

}

//Contact
type ContactProps = {
  children?: React.ReactNode
}

function Contact(props: ContactProps) {

  const location = useLocation();

  //トレイリングスラッシュ（末尾のスラッシュ）の削除
  if (location.pathname.slice(-1) === '/') return <Navigate to={location.pathname.slice(0, -1)} />

  return (
    <ContactContents location={location}>
      {props.children}
    </ContactContents>
  );

}

export default Contact;
