import React from 'react';
import { Navigate, useLocation, NavLink } from 'react-router-dom';

import Head from "../component/Head";
import Layout from '../component/Layout';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import HomeIcon from '@mui/icons-material/Home';

//NotFoundContents
type NotFoundContentsProps = {
  location: {
    pathname: string;
  };
  children?: React.ReactNode
}

const NotFoundContents = (props: NotFoundContentsProps) => {

  const pageUrl = process.env.REACT_APP_APP_URL;
  const location = props.location;

  return (
    <React.Fragment>
      <Head
        pageTitle="お探しのページが見つかりません | のし紙・掛け紙の作成サイト のしかけジェネレーター"
        pageUrl={`${pageUrl}${location.pathname}`}
      />
      <Layout>
        <Container
          sx={{
            minHeight: { xs: 'calc(100vh - 48px - 60px)', md: 'calc(100vh - 64px - 60px)', },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: { xs: 0, },
            py: { xs: 2, md: 4 },
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item>
              <Card sx={{ maxWidth: 520, }}>
                <CardHeader
                  title="お探しのページが見つかりませんでした"
                  titleTypographyProps={{
                    component: 'h2',
                    variant: "h6",
                    sx: {
                      fontWeight: 'fontWeightBold',
                    }
                  }}
                />
                <Divider />
                <CardContent>
                  <Typography>このURLのページが見つかりませんでした。<br />お探しのページが削除されたか、間違ったURLである可能性があります。</Typography>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end', }}>
                  <Button
                    component={NavLink}
                    to='/'
                    size="small"
                    color="primary"
                    startIcon={<HomeIcon />}
                  >
                    アプリトップへ
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </React.Fragment>
  );

};

//NotFound
type NotFoundProps = {
  children?: React.ReactNode
}

function NotFound(props: NotFoundProps) {

  const location = useLocation();

  //トレイリングスラッシュ（末尾のスラッシュ）の削除
  if (location.pathname.slice(-1) === '/') return <Navigate to={location.pathname.slice(0, -1)} />

  return (
    <NotFoundContents location={location}>
      {props.children}
    </NotFoundContents>
  );

}

export default NotFound;