import React, {useState, useEffect} from 'react';
import WebFont from 'webfontloader';

import Head from "../component/Head";
import Layout from '../component/Layout';
import Noshi from '../component/Noshi';
import { ReactComponent as Typo } from '../image/typo.svg';
import { ReactComponent as MizuhikiAwabi } from '../image/mizuhiki_awabi.svg';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

//HomeContents
type HomeContentsProps = {
  children?: React.ReactNode
}

const HomeContents = (props: HomeContentsProps) => {

  const pageUrl = process.env.REACT_APP_APP_URL;

  const [fontVerticalLoaded, setFontVerticalLoaded] = useState(false);
  const [fontGoogleLoaded, setFontGoogleLoaded] = useState(false);

  useEffect(() => {

    WebFont.load({
      custom: { families: ['nsjpbv'], },
      active: () => setFontVerticalLoaded(true),
    });
    WebFont.load({
      google: { families: ['Noto Serif JP'], },
      active: () =>  setFontGoogleLoaded(true),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Head
        pageTitle="のし紙・掛け紙の作成サイト のしかけジェネレーター"
        pageDesc={"のしかけジェネレーターは、のし紙・掛け紙を無料で作成できるWebサービスです。ご新物や贈答品に利用される、のし紙・掛け紙がWeb上の操作で簡単にでき、ダウンロードも無制限で可能です。"}
        pageUrl={`${pageUrl}`}
      />
      <Layout>
        <Box
          sx={{
            height: { xs: "230px", sm: "340px", md: "560px", },
            backgroundColor: '#f7f4ec',
            mx: { xs: -2, sm: -3, md: -4, },
            mb: { xs: "-181px", sm: "-205px", md: "-244px", },
            position: "relative",
            zIndex: 1,
          }}
        />
        <Container
          component={'section'}
          sx={{
            minHeight: { xs: 'calc(100vh - 48px - 60px)', md: 'calc(100vh - 64px - 60px)', },
            px: { xs: 0, },
            py: { xs: 5, sm: 6, md: 7, },
            position: "relative",
            zIndex: 2,
          }}
        >
          <Box
            mx={'auto'}
            sx={{
              px: { xs: 2, sm: 3, md: 4 },
              maxWidth: "1084px",
              position: "relative",
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                width: "290px",
                mt: { xs: "-6px", md: "-102px", },
                ml: { xs: "-34px", sm: "-25px", md: "-16px",},
                backgroundColor: "#f7f4ec",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 2,
                transform: "translateY(-100%)",
              }}
            >
              <MizuhikiAwabi style={{ verticalAlign: "top", }} />
            </Box>
            <Box
              sx={{
                width: "100vw",
                height: "18px",
                mt: { xs: "-52px", md: "-148px", },
                backgroundColor: 'secondary.main',
                backgroundImage: "linear-gradient(transparent 50%, #f7f4ec 50%)",
                backgroundSize: "100% 4px",
                position: "absolute",
                top: 0,
                left: "50%",
                zIndex: 1,
                transform: "translate(-50%, -100%)",
              }}
            />
            <Typography
              component="p"
              variant="h4"
              sx={{
                color: "primary.main",
                fontFamily: "'Lora', serif",
                fontSize: { xs: "1.75rem", md: "2.5rem", },
                fontWeight: 300,
                letterSpacing: "0.0125em",
                position: "relative",
                zIndex: 3,
              }}
            >
              Generator
            </Typography>
            <Typography
              component="h2"
              mt={1}
              ml={0.25}
              sx={{
                color: "text.secondary",
                fontSize: "0.75rem",
                letterSpacing: "0.05em",
                fontFeatureSettings: "'palt'",
                position: "relative",
                zIndex: 3,
              }}
            >
              のし紙・掛け紙の作成
            </Typography>
          </Box>
          <Paper
            sx={{
              py: { xs: 2.5, sm: 3, md: 3.5, },
              px: { xs: 2, sm: 3, md: 4 },
              my: { xs: 5, sm: 6, md: 7, },
              position: "relative",
              zIndex: 2,
            }}
          >
            <Box
              mx={'auto'}
              sx={{
                maxWidth: '1020px',
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  width: { xs: "100px", sm: "176px", md: "290px", },
                }}
              >
                <Typo style={{ verticalAlign: "top", }} />
              </Box>
            </Box>
            {fontVerticalLoaded && fontGoogleLoaded && (
              <Noshi />
            )}
            {/* <Box
              component={'section'}
              my={{ xs: 5, sm: 6, md: 7, }}
              mx={'auto'}
              sx={{
                maxWidth: '1020px',
                position: "relative",
              }}
            >
              <Box
                px={2}
                py={1.5}
                sx={{
                  backgroundColor: "grey.100",
                  borderLeftColor: "secondary.main",
                  borderLeftWidth: "3px",
                  borderLeftStyle: "solid",
                }}
              >
                <Typography
                  component="h3"
                  sx={{
                    color: "text.main",
                    fontSize: { xs: "1.0rem", sm: "1.25rem", },
                    lineHeight: { xs: 1.4, sm: 1.5, },
                    fontWeight: "fontWeightBold",
                  }}
                >
                  のし紙・かけ紙画像の調整方法について
                </Typography>
              </Box>
            </Box> */}
          </Paper>
        </Container>
      </Layout>
    </React.Fragment>
  );

}

//Home
type HomeProps = {
  children?: React.ReactNode
}

function Home(props: HomeProps) {

  return (
    <HomeContents>
      {props.children}
    </HomeContents>
  );

}

export default Home;
