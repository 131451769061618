import React from 'react';
import { signOut } from "firebase/auth";
import { Link as RouterLink, useNavigate, useLocation, NavLink } from 'react-router-dom';

import logo from '../image/logo.svg';
import { auth } from './firebase';
import { useAuthContext } from './auth';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function HeaderContents() {

  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useAuthContext();
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOnClickLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/signin');
      }).catch((error) => {
        console.log(error);
      });
  };
  const handleOnClickMenuToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <Typography component={location.pathname === '/' ? 'h1' : 'p'}>
        <Link
          component={RouterLink}
          to="/"
          sx={{
            width: { xs: '213.66px', md: '276.97px', },
            height: 0,
            display: 'block',
            overflow: 'hidden',
            position: 'relative',
            pt: { xs: '27px', md: '35px', },
            '&::after': {
              content: '""',
              display: 'block',
              width: '100%',
              height: '100%',
              backgroundImage: `url(${logo})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              position: 'absolute',
              top: 0,
              left: 0,
            },
          }}
        >
          のしかけジェネレーター
        </Link>
      </Typography>
      <Box component="span" sx={{ flexGrow: 1 }}></Box>
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          backgroundColor: 'grey.50',
          border: '1px solid',
          borderColor: 'grey.200',
          pl: 0.5,
          pr: 2,
          py: 0.5,
          borderRadius: '26px',
        }}
      >
        {authContext?.isSignedIn ? (
          <React.Fragment>
            <Avatar
              src={`${authContext.isSignedIn.photoURL}`}
              alt={`${authContext.isSignedIn.displayName}`}
              sx={{ width: 24, height: 24 }}
            />
            <Box
              sx={{
                pl: 1,
                wordBreak: 'break-all',
                maxWidth: '200px',
                display: 'flex',
                alignItems: 'center',
              }}>
              <Typography
                component='p'
                variant="subtitle2"
                sx={{
                  lineHeight: '1.2',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {authContext.isSignedIn.displayName}さん
              </Typography>
              <Box mr={-1.5} sx={{ lineHeight: 1, }}>
                <IconButton
                  size="small"
                  onClick={handleOnClickMenuToggle}
                  sx={{
                    p: 0.5,
                    ml: 0.5,
                    '& svg': {
                      fontSize: '1rem',
                    }
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      borderRadius: '2px',
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 7,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem
                    disableRipple
                    sx={{
                      cursor: 'default',
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                  >
                    <Typography
                      component='p'
                      variant="caption"
                      sx={{
                        lineHeight: '1.2',
                        color: 'grey.600',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {authContext.isSignedIn.email}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Avatar sx={{ width: 24, height: 24 }}>G</Avatar>
            <Box
              sx={{
                ml: 1,
                wordBreak: 'break-all',
                maxWidth: '200px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                component='p'
                variant="subtitle2"
                sx={{
                  lineHeight: '1.2',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                ゲストさん
              </Typography>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <Box ml={2} sx={{ display: { xs: 'none', md: 'flex' }, }}>
        {authContext?.isSignedIn ? (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<LogoutIcon />}
            onClick={handleOnClickLogout}
          >
            ログアウト
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<LoginIcon />}
            onClick={handleOnClickLogout}
            component={NavLink}
            to='/signin'
          >
            ログイン
          </Button>
        )}
      </Box>
    </Box>
  );

};

export default HeaderContents;