import React, { useState } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import { sendSignInLinkToEmail, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { auth } from '../component/firebase';
import { useAuthContext } from '../component/auth';
import Head from "../component/Head";
import Layout from '../component/Layout';

import { SxProps, Theme } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import SvgIcon from '@mui/material/SvgIcon';
import LockOpenIcon from '@mui/icons-material/LockOpen';

//GoogleIcon
type GoogleIconProps = {
  sx?: SxProps<Theme>;
}

const GoogleIcon = (props: GoogleIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M23.52 12.273a13.788 13.788 0 0 0-.218-2.455H12v4.642h6.458a5.52 5.52 0 0 1-2.394 3.622v3.01h3.878a11.703 11.703 0 0 0 3.578-8.82Z" style={{ fill: '#4285f4', fillRule: 'evenodd' }} />
      <path d="M12 24a11.457 11.457 0 0 0 7.942-2.907l-3.878-3.011A7.24 7.24 0 0 1 5.285 14.28H1.276v3.11A11.995 11.995 0 0 0 12 24Z" style={{ fill: '#34a853', fillRule: 'evenodd' }} />
      <path d="M5.285 14.28a7.094 7.094 0 0 1 0-4.56V6.61H1.276a12.015 12.015 0 0 0 0 10.78l4.01-3.11Z" style={{ fill: '#fbbc05', fillRule: 'evenodd' }} />
      <path d="M12 4.773a6.483 6.483 0 0 1 4.587 1.794l3.442-3.442A11.533 11.533 0 0 0 12 0 11.995 11.995 0 0 0 1.276 6.61l4.01 3.11A7.152 7.152 0 0 1 12 4.773Z" style={{ fill: '#ea4335', fillRule: 'evenodd' }} />
      <path d="M0 0h24v24H0Z" style={{ fill: 'none' }} />
    </SvgIcon>
  );
}

//SignInContents
type SignInContentsProps = {
  location: {
    pathname: string;
  };
  children?: React.ReactNode
}
type EmailProps = {
  value: string,
  error?: boolean,
  helperText?: string,
}
type AlertProps = {
  open: boolean,
  type?: "success" | "info" | "warning" | "error",
  title?: string,
  message?: string
}

const SignInContents = (props: SignInContentsProps) => {

  const navigate = useNavigate();
  const pageUrl = process.env.REACT_APP_APP_URL;
  const location = props.location;
  const [emailProps, setEmailProps] = useState<EmailProps>({
    value: '',
  });
  const [alertProps, setAlertProps] = useState<AlertProps>({
    open: false,
  });
  const actionCodeSettings = {
    url: `${pageUrl}/signinlink`,
    handleCodeInApp: true,
  };
  const handleOnChangeEmail = (event: any) => {
    if (event.currentTarget.value.length > 0) {
      setEmailProps({
        value: event.currentTarget.value,
      });
    } else {
      setEmailProps({
        value: '',
      });
    }
  };
  const handleOnSubmit = (event: any) => {
    event.preventDefault();
    sendSignInLinkToEmail(auth, emailProps.value, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForWebService1SignIn', emailProps.value);
        setAlertProps({
          open: true,
          type: 'success',
          title: 'メールを送信しました',
          message: 'メールに記載のリンクからログインしてください',
        });
      })
      .catch((error) => {
        setAlertProps({
          open: true,
          type: 'error',
          title: '登録できませんでした',
          message: `${error.message}`,
        });

        switch (error.code) {
          case 'auth/missing-email':
            setEmailProps({
              value: '',
              error: true,
              helperText: 'メールアドレスが入力されていません',
            });
            break;
          case 'auth/invalid-email':
            setEmailProps({
              value: emailProps.value,
              error: true,
              helperText: 'メールアドレスの形式が正しくありません',
            });
            break;
          case 'auth/email-already-in-use':
            setEmailProps({
              value: emailProps.value,
              error: true,
              helperText: 'すでに登録済みのメールアドレスです',
            });
            break;
          default:
            setEmailProps({
              value: '',
              error: true,
            });
            break;
        }

      });
  };
  const handleOnClickGoogleAuth = () => {
    auth.languageCode = 'ja';
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        navigate('/');
      }).catch((error) => {
        if (error.code !== 'auth/popup-closed-by-user') {
          setAlertProps({
            open: true,
            type: 'error',
            title: 'ログインできませんでした',
            message: `${error.message}`,
          });
        }
      });
  }
  const handleOnCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertProps({
      open: false,
    });
  };

  return (
    <React.Fragment>
      <Head
        pageTitle="サインイン | のし紙・掛け紙の作成サイト のしかけジェネレーター"
        pageUrl={`${pageUrl}${location.pathname}`}
      />
      <Layout>
        <Container
          sx={{
            minHeight: { xs: 'calc(100vh - 48px - 60px)', md: 'calc(100vh - 64px - 60px)', },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: { xs: 0, },
            py: { xs: 2, md: 4 },
          }}
        >
          <Grid
            container
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid item>
              <Card sx={{ maxWidth: 520, }}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: 'secondary.main', }}>
                      <LockOpenIcon />
                    </Avatar>
                  }
                  title="ログイン"
                  titleTypographyProps={{
                    component: 'h1',
                    variant: 'h6',
                    sx: { fontWeight: 'fontWeightBold', }
                  }}
                  sx={{ borderBottom: 1, borderColor: 'divider', }}
                />
                <CardContent>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleOnSubmit}
                  >
                    <Alert severity="info" sx={{ my: 1, '& .MuiAlert-message br': { display: { xs: 'none', md: 'inline', } } }}>メールアドレスを入力し、ログインボタンを押してください。<br/>ご入力いただいたメールアドレス宛にログイン用のリンクを送信します。</Alert>
                    <TextField
                      type="email"
                      name="email"
                      label="メールアドレス"
                      variant="outlined"
                      fullWidth
                      required
                      error={emailProps.error}
                      helperText={emailProps.helperText}
                      margin="normal"
                      onChange={(event) => handleOnChangeEmail(event)}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      disableElevation
                      fullWidth
                      sx={{
                        mt: 2.5,
                        mb: 1.5,
                        py: 1.125,
                        px: 2.125,
                      }}
                    >
                      ログイン
                    </Button>
                  </Box>
                  <Divider sx={{ my: 3, }}>
                    <Chip label='または' />
                  </Divider>
                  <Box>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{
                        mt: 2,
                        mb: 2,
                        py: 1,
                        px: 2,
                        fontFamily: "'Roboto', sans-serif",
                      }}
                      onClick={handleOnClickGoogleAuth}
                      color="google"
                    >
                      <GoogleIcon sx={{ fontSize: 18, mr: 2, }} />
                      Googleでログイン
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Snackbar
            open={alertProps.open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={handleOnCloseAlert}
            sx={{ bottom: 50, }}
          >
            <Alert
              severity={alertProps.type}
              onClose={handleOnCloseAlert}
              sx={{
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: `${alertProps.type}.main`,
              }}
            >
              {alertProps.title && <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>{alertProps.title}</AlertTitle>}
              {alertProps.message}
            </Alert>
          </Snackbar>
        </Container>
      </Layout>
    </React.Fragment>
  );

};


//SignIn
type SignInProps = {
  children?: React.ReactNode
}

function SignIn(props: SignInProps) {

  const location = useLocation();
  const authContext = useAuthContext();

  //トレイリングスラッシュ（末尾のスラッシュ）の削除
  if (location.pathname.slice(-1) === '/') return <Navigate to={location.pathname.slice(0, -1)} />
  //サインインしていればHOMEにページ遷移
  if (authContext?.isSignedIn) return <Navigate to="/" />;

  return (
    <SignInContents location={location}>
      {props.children}
    </SignInContents>
  );

}

export default SignIn;
