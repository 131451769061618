import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate, Link as RouterLink, useLocation } from 'react-router-dom';
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

import { auth } from '../component/firebase';
import { useAuthContext } from '../component/auth';
import Head from "../component/Head";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import AssignmentIcon from '@mui/icons-material/Assignment';

//SignInLinkContents
type SignInLinkContentssProps = {
  location: {
    pathname: string;
  };
  children?: React.ReactNode
}
type EmailProps = {
  value: string,
  error?: boolean,
  helperText?: string,
}
type AlertProps = {
  open: boolean,
  type?: "success" | "info" | "warning" | "error",
  title?: string,
  message?: string
}

const SignInLinkContents = (props: SignInLinkContentssProps) => {

  const navigate = useNavigate();
  const pageUrl = process.env.REACT_APP_APP_URL;
  const location = props.location;
  const [emailProps, setEmailProps] = useState<EmailProps>({
    value: ''
  });
  const [alertProps, setAlertProps] = useState<AlertProps>({
    open: false
  });
  const handleOnChangeEmail = (event: React.SyntheticEvent | Event) => {
    if (!(event.currentTarget instanceof HTMLInputElement)) return;
    if (event.currentTarget.value.length > 0) {
      setEmailProps({
        value: event.currentTarget.value,
      });
    } else {
      setEmailProps({
        value: '',
      });
    }
  };
  const signIn = (email: string) => {
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        window.localStorage.removeItem('emailForWebService1SignIn');
        navigate('/');
      })
      .catch((error) => {
        setAlertProps({
          open: true,
          type: 'error',
          title: '登録できませんでした',
          message: `${error.message}`,
        });

        switch (error.code) {
          case 'auth/missing-email':
            setEmailProps({
              value: '',
              error: true,
              helperText: 'メールアドレスが入力されていません',
            });
            break;
          case 'auth/invalid-email':
            setEmailProps({
              value: email,
              error: true,
              helperText: 'メールアドレスの形式が正しくありません',
            });
            break;
          case 'auth/invalid-action-code':
            setEmailProps({
              value: email,
              error: true,
              helperText: 'ログイン用リンクが既に無効となっています',
            });
            break;
          default:
            setEmailProps({
              value: '',
              error: true,
            });
            break;
        }

      });
  }
  const handleOnSubmit = (event: React.SyntheticEvent | Event, email: string) => {
    event.preventDefault();
    signIn(email);
  };
  const handleOnCloseAlert = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertProps({
      open: false,
    });
  };

  useEffect(() => {

    const storageEmail = window.localStorage.getItem('emailForWebService1SignIn');
    if (storageEmail) {
      setEmailProps({
        value: storageEmail,
      });
      signIn(storageEmail);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isSignInWithEmailLink(auth, window.location.href)) return <Navigate to="/" />;

  return (
    <React.Fragment>
      <Head
        pageTitle="サインインリンク、ディスクリプション | のし紙・掛け紙の作成サイト のしかけジェネレーター"
        pageUrl={`${pageUrl}${location.pathname}`}
      />
      <Container
        sx={{
          minHeight: { xs: 'calc(100vh - 48px - 60px)', md: 'calc(100vh - 64px - 60px)', },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: { xs: 0, },
          py: { xs: 2, md: 4 },
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid item>
            <Card sx={{ maxWidth: 520, }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'primary.main', }}>
                    <AssignmentIcon />
                  </Avatar>
                }
                title='ログイン'
                titleTypographyProps={{
                  component: 'h1',
                  variant: 'h6',
                  sx: { fontWeight: 'fontWeightBold', }
                }}
                sx={{ borderBottom: 1, borderColor: 'divider', }}
              />
              <CardContent>
                <Box
                  component="form"
                  noValidate
                  onSubmit={(event) => handleOnSubmit(event, emailProps.value)}
                  mb={1}
                >
                  <Alert severity="info" sx={{ my: 1, }}>確認のため、新規登録時に入力したメールアドレスと同じものを入力してください。</Alert>
                  <TextField
                    type="email"
                    name="email"
                    label="メールアドレス"
                    variant="outlined"
                    fullWidth
                    required
                    error={emailProps.error}
                    helperText={emailProps.helperText}
                    margin="normal"
                    onChange={(event) => handleOnChangeEmail(event)}
                    value={emailProps.value}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    fullWidth
                    sx={{
                      mt: 2.5,
                      mb: 1.5,
                      py: 1.125,
                      px: 2.125,
                    }}
                  >
                    ログイン
                  </Button>
                  <Grid container direction={{ xs: 'column', }}>
                    <Grid
                      item
                      xs
                      sx={{ textAlign: { xs: 'right', }, }}
                    >
                      <Link
                        component={RouterLink}
                        to="/signin"
                        variant="body2"
                      >
                        ログインページ
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Snackbar
          open={alertProps.open}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={6000}
          onClose={handleOnCloseAlert}
          sx={{ bottom: 50, }}
        >
          <Alert
            severity={alertProps.type}
            onClose={handleOnCloseAlert}
            sx={{
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: `${alertProps.type}.main`,
            }}
          >
            {alertProps.title && <AlertTitle sx={{ fontWeight: 'fontWeightBold', }}>{alertProps.title}</AlertTitle>}
            {alertProps.message}
          </Alert>
        </Snackbar>
      </Container>
    </React.Fragment>
  );

};

//SignInLink
type SignInProps = {
  children?: React.ReactNode
}

function SignInLink(props: SignInProps) {

  const location = useLocation();
  const authContext = useAuthContext();

  //トレイリングスラッシュ（末尾のスラッシュ）の削除
  if (location.pathname.slice(-1) === '/') return <Navigate to={location.pathname.slice(0, -1)} />
  //サインインしていればHOMEにページ遷移
  if (authContext?.isSignedIn) return <Navigate to="/" />;

  return (
    <SignInLinkContents location={location}>
      {props.children}
    </SignInLinkContents>
  );

}

export default SignInLink;