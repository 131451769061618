import React from 'react';
import { NavLink } from 'react-router-dom';

import Link from '@mui/material/Link';

function SubMenu() {

  const subMenuList = [
    { label: 'ご利用について', url: '/about', },
    { label: 'プライバシーポリシー', url: '/privacy', },
    { label: 'お問い合わせ', url: '/contact', },
  ];

  return (
    <React.Fragment>
      {subMenuList.map((item, index) => {
        return (
          <Link
            component={NavLink}
            to={item.url}
            key={index}
            sx={{
              color: 'text.primary',
              textDecoration: 'none',
              fontSize: '0.75rem',
              '&:hover': {
                color: 'text.secondary',
              },
            }}
          >
            {item.label}
          </Link>
        )
      })}
    </React.Fragment>
  );
}

export default SubMenu;